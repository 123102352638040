//@ts-nocheck
import palette from './palette'
import media from './media'

export default {
  '.text-center': {
    textAlign: 'center !important',
  },

  '.text-left': {
    textAlign: 'left !important',
  },

  '.text-right': {
    textAlign: 'right !important',
  },

  '.text-yellow': {
    color: `${palette.yellow}!important`,
  },

  '.text-blue': {
    color: `${palette.blue}!important`,
  },

  '.text-accent': {
    color: `${palette.accent}!important`,
  },

  '.text-white': {
    color: '#ffffff!important',
  },

  '.text-upper': {
    textTransform: 'uppercase',
  },

  '.text-lower': {
    textTransform: 'lowercase',
  },

  '.relative': {
    position: 'relative',
  },

  '.transform-reverse': {
    transform: 'rotate(180deg)',
  },

  '.list-reset': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },

  '.text-nowrap': {
    whiteSpace: 'nowrap',
  },

  '.text-preline': {
    whiteSpace: 'pre-line',
  },

  '.text-ellipsis': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '.hidden-sm': {
    [media.max.sm]: {
      display: 'none',
    },
  },

  '.visible-sm': {
    display: 'none',

    [media.max.sm]: {
      display: 'block',
    },
  },

  ...generateMarginPadding(),
}

function generateMarginPadding() {
  let i = 0
  const obj = {
    '.m-l-auto': {
      marginLeft: 'auto',
    },
    '.m-r-auto': {
      marginRight: 'auto',
    },
  }

  while (i <= 50) {
    obj[`.p-${i}`] = {
      padding: `${i}px !important`,
    }
    obj[`.m-${i}`] = {
      padding: `${i}px !important`,
    }
    obj[`.p-x-${i}`] = {
      paddingLeft: `${i}px !important`,
      paddingRight: `${i}px !important`,
    }
    obj[`.m-x-${i}`] = {
      marginRight: `${i}px !important`,
      marginLeft: `${i}px !important`,
    }
    obj[`.p-y-${i}`] = {
      paddingTop: `${i}px !important`,
      paddingBottom: `${i}px !important`,
    }
    obj[`.m-y-${i}`] = {
      marginTop: `${i}px !important`,
      marginBottom: `${i}px !important`,
    }
    obj[`.m-l-${i}`] = {
      marginLeft: `${i}px !important`,
    }
    obj[`.m-r-${i}`] = {
      marginRight: `${i}px !important`,
    }
    obj[`.m-t-${i}`] = {
      marginTop: `${i}px !important`,
    }
    obj[`.m-b-${i}`] = {
      marginBottom: `${i}px !important`,
    }
    obj[`.p-l-${i}`] = {
      paddingLeft: `${i}px !important`,
    }
    obj[`.p-r-${i}`] = {
      paddingRight: `${i}px !important`,
    }
    obj[`.p-t-${i}`] = {
      paddingTop: `${i}px !important`,
    }
    obj[`.p-b-${i}`] = {
      paddingBottom: `${i}px !important`,
    }

    i += 5
  }

  return obj
}
