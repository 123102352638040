import React, { createContext } from 'react'

export const SettingsContext = createContext({
  settings: {
    s3Images: {
      source: '',
      thumbWeb: '',
      thumbMobile: '',
      thumbSquare: '',
    },
    stripe: {
      publishableKey: '',
    },
    versionInfo: {
      current: '',
      minimal: '',
    },
  },
})

export type TS3Images = {
  source: string
  thumbWeb: string
  thumbMobile: string
  thumbSquare: string
}

export type TVersionInfo = {
  current: string
  minimal: string
}

export type TStripeSettings = {
  publishableKey: string
}

interface SettingsContextProps {
  settings: {
    s3Images: TS3Images
    stripe: TStripeSettings
    versionInfo: TVersionInfo
  }
  children: React.ReactNode
}

export const SettingsProvider = ({ settings, children }: SettingsContextProps) => {
  return (
    <SettingsContext.Provider
      value={{
        settings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
