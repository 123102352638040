import layout from 'src/theme/layout'
import helpers from 'src/theme/helpers'
import calendar from 'src/theme/calendar'
import modal from 'src/theme/modal'

import fonts from 'src/theme/fonts'
import { createUseStyles } from 'react-jss'
import { palette } from 'src/theme/index'

export default createUseStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },

    '#__next, html, body': {
      minHeight: '100vh',
      width: '100vw',
      fontFamily: fonts.family.primary,
      lineHeight: 1.2,
      fontWeight: fonts.weight.primary.regular,
      background: palette.white,
      margin: 0,
      padding: 0,
      overflowX: 'hidden',
    },

    'h1, h2, h3, h4, h5, ol, ul, p': {
      margin: 0,
      padding: 0,
    },

    'button, label': {
      fontFamily: fonts.family.primary,
    },

    'input, textarea': {
      fontFamily: fonts.family.primary,
      fontWeight: fonts.weight.primary.regular,
      fontSize: 16,
    },

    a: {
      outline: 'none',
      textDecoration: 'none',

      '&.link': {
        color: palette.blue,

        '&:hover': {
          textDecoration: 'underline',
        },

        '&.underline': {
          textDecoration: 'underline',

          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },

    img: {
      border: 0,
    },

    ul: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        margin: 0,
        padding: 0,
        textIndent: 0,
        listStyleType: 'none',
      },
    },

    strong: {
      fontWeight: fonts.weight.primary.bold,
    },

    '.sticky-inner-wrapper': {
      zIndex: 10,
    },

    ...helpers,
    ...layout,
    ...modal,
    ...calendar,
  },
})
