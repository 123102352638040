//@ts-nocheck
import React, { useEffect } from 'react'
import App from 'next/app'
import * as Sentry from '@sentry/node'
import { LocaleProvider } from 'src/context/LocaleContext'
import { SettingsProvider } from 'src/context/SettingsContext'
import { onFetchData } from 'src/utils/API'
import API from 'src/utils/API'
import { useRouter } from 'next/router'
import { GTMPageView } from 'src/utils/gtm'
import { CookiesProvider } from 'react-cookie';
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/ru'
import 'dayjs/locale/pt'

import RModal from 'react-modal'

import useAppSheet from 'src/theme/application'

import 'react-calendar/dist/Calendar.css'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-phone-input-2/lib/material.css'
import 'src/components/Star/stars-fix.css' // Fix intends

// First version of Translations was based on https://github.com/fwojciec/simple-i18n-example
// Alternative recommended by Senior of NextJS ->
// https://github.com/vercel/next.js/pull/13511
// https://github.com/vinissimus/next-translate#use-translations-in-your-pages
// Current version based on https://github.com/vercel/next.js/discussions/10651#discussioncomment-8243
// https://codesandbox.io/s/nextjs-i18n-staticprops-new-ouyrb
// Discussions https://github.com/vercel/next.js/discussions/10651
// prev problem: component wrapper withLocale had getInitialProps which had conflict with getStaticProps getStaticPath

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

export default function MyApp({ Component, pageProps, err }) {
  const { lang, translations, namespaces, settings } = pageProps
  const router = useRouter()

  useAppSheet()

  useEffect(() => {
    dayjs.locale(lang)
  }, [lang])

  useEffect(() => {
    const style = document.getElementById('server-side-styles')

    RModal.setAppElement('#__next')

    if (style && style.parentNode) {
      style.parentNode.removeChild(style)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      GTMPageView(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <LocaleProvider lang={lang} translations={translations} namespaces={namespaces}>
      <SettingsProvider settings={settings}>
        <CookiesProvider>
          {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
          <Component {...pageProps} err={err} />
        </CookiesProvider>
      </SettingsProvider>
    </LocaleProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)

  const settings = await onFetchData({
    api: API.getSettings,
  })

  return { ...appProps, pageProps: { ...appProps.pageProps, settings } }
}
