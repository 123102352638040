import palette from './palette'
import { fonts, media } from 'src/theme/index'

export default {
  '.react-calendar': {
    fontFamily: fonts.family.primary,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: 15,
    borderColor: '#EDEDED',
    overflow: 'hidden',

    [media.max.sm]: {
      width: 280,
    },
  },

  '.react-calendar__navigation': {
    marginBottom: 0,
  },

  '.react-calendar__navigation__label__labelText': {
    fontSize: 17,
    fontFamily: fonts.family.primary,
    fontWeight: fonts.weight.primary.bold,
  },

  '.react-calendar__navigation__arrow': {
    fontSize: 24,
    color: palette.blue,
  },

  '.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button': {
    display: 'none',
  },

  '.react-calendar__year-view': {
    '& .react-calendar__tile--now': {
      background: palette.blue,
      color: '#ffffff',
      padding: [20, 10],
      fontWeight: fonts.weight.primary.bold,
    },

    '& .react-calendar__tile--now:enabled:hover, &.react-calendar__tile--now:enabled:focus': {
      background: '#3297e0',
    },
  },

  '.react-calendar__month-view': {
    '& .react-calendar__month-view__weekdays__weekday': {
      color: '#979797',

      '& abbr': {
        textDecoration: 'none',
        fontFamily: fonts.family.primary,
        fontWeight: fonts.weight.primary.bold,
        fontSize: 11,

        [media.min.sm]: {
          fontSize: 12,
        },
      },
    },

    '& .react-calendar__tile': {
      color: '#000000',
      borderRadius: 30,
      padding: 2,

      [media.min.sm]: {
        padding: 5,
      },

      '&:hover': {
        backgroundColor: '#ffffff',

        '& abbr': {
          backgroundColor: '#e6e6e6',
        },
      },

      '&[disabled]': {
        textDecoration: 'line-through',
        color: '#E1E1E1',
        backgroundColor: '#ffffff',

        '&.react-calendar__tile--hover': {
          backgroundColor: '#ffffff',

          '& abbr': {
            backgroundColor: '#ffffff',
          },
        },

        '&:hover': {
          backgroundColor: '#ffffff',

          '& abbr': {
            backgroundColor: '#ffffff',
          },
        },

        '&.react-calendar__tile--active': {
          '&:hover': {
            backgroundColor: '#ffffff',

            '& abbr': {
              background: palette.blue,
              color: 'white',
              opacity: 0.7,
            },
          },
        },
      },

      '& abbr': {
        fontFamily: fonts.family.primary,
        fontWeight: fonts.weight.primary.semiBold,
        fontSize: 15,
        height: 36,
        width: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        borderRadius: 30,

        [media.max.sm]: {
          height: 30,
          width: 30,
          fontSize: 14,
        },
      },

      '&.react-calendar__tile:enabled:hover, &.react-calendar__tile:enabled:focus': {
        backgroundColor: '#ffffff',
      },

      '&.react-calendar__tile--now': {
        backgroundColor: '#ffffff',
      },

      '&.react-calendar__tile--now:enabled:hover, &.react-calendar__tile--now:enabled:focus': {
        backgroundColor: '#ffffff',
      },

      '&.react-calendar__tile--hasActive': {
        backgroundColor: '#ffffff',

        '& abbr': {
          background: '#76baff',
        },
      },

      '&.react-calendar__tile--hasActive:enabled:hover, &.react-calendar__tile--hasActive:enabled:focus': {
        backgroundColor: '#ffffff',

        '& abbr': {
          background: '#a9d4ff',
        },
      },

      '&.react-calendar__tile--active': {
        backgroundColor: '#ffffff',

        '& abbr': {
          background: palette.blue,
          color: 'white',
        },
      },

      '&.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus': {
        backgroundColor: '#ffffff',

        '& abbr': {
          background: palette.blue,
        },
      },
    },
  },

  '.react-calendar--selectRange': {
    '& .react-calendar__tile.react-calendar__tile--hover': {
      backgroundColor: '#ffffff',

      '& abbr': {
        backgroundColor: '#e6e6e6',
        color: '#000000',
      },
    },
  },
}
